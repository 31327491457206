import React from "react";
import { CustomCalander, CustomDropDown, CustomForm, CustomInput } from "shared/AllInputs";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import CustomButton, {
    Buttonlayout,
    CustomButtonOutlined,
} from "shared/CustomButton";
import ServiceCotainer from "./ServiceContainer";
import { propertyRoomOpt, ServicesType } from "shared/AllDropDownOptions";

const ServiceForm = ({ type }) => {
    const { data, handleChange, onSubmit, loading, navigate } = ServiceCotainer();
    return (
        <Page
            title={`${type} Service`}
            description={`Let’s ${type} your service Details`}
            navigateLink={"/services"}
        >
            <CustomCard>
                <CustomForm title="Service Setup">
                    <CustomInput
                        data={data}
                        onChange={handleChange}
                        name="servicesName"
                        required
                    />
                    <CustomDropDown
                        data={data}
                        onChange={handleChange}
                        name="servicesType"
                        options={ServicesType}
                        required
                    />
                    <CustomDropDown
                        data={data}
                        onChange={handleChange}
                        name="property"
                        options={propertyRoomOpt}
                        placeholder="Select Property/Room"
                        label={"Property/Room"}
                        required
                    />
                    <CustomInput
                        data={data}
                        onChange={handleChange}
                        name="legalReference"
                        required
                    />
                    <CustomInput
                        data={data}
                        onChange={handleChange}
                        name="nickName"
                        required
                    />
                    <CustomCalander
                        minDate={new Date()}
                        data={data}
                        onChange={handleChange}
                        name="startDate"
                        required
                    />
                    <CustomCalander
                        minDate={data?.startDate}
                        data={data}
                        onChange={handleChange}
                        name="endDate"
                        required
                    />
                </CustomForm>
            </CustomCard>
            <Buttonlayout>
                <CustomButton
                    onClick={onSubmit}
                    label={type === "Edit" ? "Save Changes" : "Save"}
                    loading={loading}
                />
                <CustomButtonOutlined
                    label="Cancel"
                    onClick={() => navigate("/services")}
                />
            </Buttonlayout>
        </Page>
    );
};

export default ServiceForm;
