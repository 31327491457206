export const ServicesType = [
    { name: "Solo Living", value: "solo living" },
    { name: "Multi Living", value: "multi living" },
]

export const propertyRoomOpt = [
    { name: "Property", value: "property" },
    { name: "Room", value: "room" },
]

export const YesNoOptions = [
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
]

export const PositionOptions = [
    { name: "Manager", value: "manager" },
    { name: "Executive", value: "executive" },
    { name: "Team Lead", value: "teamLead" },
];