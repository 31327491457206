import React, { useEffect, useState } from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomForm } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getServiceDetailAction } from "store/actions/ServicesActions";
import EditIcon from "assets/images/icons/editicon.png";

export default function ViewService() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState({
        service_name: "",
        service_type: "",
        property: "",
        legal_reference: "",
        nick_name: "",
        start_date: "",
        end_date: "",
    });
    const { service } = useSelector((state) => state.service);

    useEffect(() => {
        if (service?.id && params?.id && service?.id == params?.id) {
            setData(service)
        } else if (params?.id) {
            getServiceDetail(params?.id);
        }
    }, [params?.id]);

    const getServiceDetail = (id) => {
        dispatch(
            getServiceDetailAction(id, dispatch, (res) => {
                setData(res);
            })
        );
    };

    const header = () => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() =>
                        navigate(`/services/edit/${params?.id}`)
                    }
                ></img>
            </div>
        );
    };

    return (
        <Page
            title={`View Service`}
            description={`Let’s View your Service Details`}
            navigateLink={"/services"}
        >
            <CustomCard>
                <CustomForm title="Service Info" header={header()}>
                    <CustomViewInput name="service_name" data={data} />
                    <CustomViewInput name="service_type" data={data} />
                    <CustomViewInput name="property" data={data} label={"Property/Room"} />
                    <CustomViewInput name="legal_reference" data={data} />
                    <CustomViewInput name="nick_name" data={data} />
                    <CustomViewInput name="start_date" data={data} />
                    <CustomViewInput name="end_date" data={data} />
                </CustomForm>
            </CustomCard>
        </Page>
    );
}
