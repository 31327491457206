import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { debounce } from "lodash";
import { CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomOverlay from "shared/CustomOverlay";
import CustomCard from "shared/CustomCard";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { deleteServiceAction, getServicesListAction } from "store/actions/ServicesActions";
import CustomDeleteModal from "shared/Modals/CustomDeleteModal";
import { setServiceSlice } from "store/slices/ServiceSlices";

const ServicesSetupList = () => {
    const openAction = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [servicesList, setServicesList] = useState([]);
    const [first, setFirst] = useState(0);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = query.get("page") || 1;
    const rows = query.get("rows") || 10;
    const [count, setCount] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const { service } = useSelector((state) => state?.service);
    const [columns, setColumns] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [loading, setLoading] = useState(false);

    useMemo(() => {
        setFirst(page * rows - rows);
    }, []);

    const header = () => {
        return (
            <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
                <div className="w-12 md:w-8 mr-3">
                    <CustomSearch
                        name="search"
                        value={search}
                        onChange={({ value }) => {
                            setSearch(value);
                            setFirst(0);
                            debouncedOnChange.current(
                                rows,
                                page,
                                value,
                            );
                        }}
                        isSearch={isSearch}
                        placeholder="Search Service"
                    />
                </div>
                <CustomButton
                    size="small"
                    icon="pi pi-plus"
                    extraClassNames="mr-3"
                    label="Add Rent Regulation"
                    onClick={() => navigate("/services/add")}
                />
            </div>
        );
    };

    const actions = (d) => {
        return (
            <CustomOverlay extraClass={"text-center"}>
                <ul className="no-style p-0">
                    <li
                        className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                        onClick={() => {
                            navigate(`/services/view/${d.id}`);
                            dispatch(setServiceSlice(d));
                        }}
                    >
                        <i className="pi pi-eye cursor-pointer text-dark"></i>
                        View
                    </li>
                    <li
                        className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                        onClick={() => {
                            setIdToDelete(d?.id);
                            setDeleteModal(true);
                        }}
                    >
                        <i className="pi pi-trash text-dark"></i>{" "}
                        Delete
                    </li>
                </ul>
            </CustomOverlay>
        );
    };

    const ActionTemplate = () => {
        return (
            <>
                <p
                    className="flex gap-2 align-items-center"
                    onClick={(e) => openAction.current.toggle(e)}
                >
                    <img src={manageColICon} style={{ width: "27px" }} />
                    <i className="pi pi-chevron-down"></i>
                </p>
            </>
        );
    };

    const columnOptionsInitial = useMemo(
        () => [
            {
                name: "Services Name",
                accessor: "service_name",
                value: true,
                frozen: true,
                filter: false,
            },
            {
                name: "Services Type",
                accessor: "service_type",
                value: true,
                filter: false,
            },
            {
                name: "Property/Room",
                accessor: "property",
                value: true,
                filter: false,
            },
            {
                name: "Legal Reference",
                accessor: "legal_reference",
                value: true,
                filter: false,
            },
            {
                name: "Nick Name",
                accessor: "nick_name",
                value: false,
                filter: false,
            },
            {
                name: "Start Date",
                accessor: "start_date",
                value: false,
                filter: false,
            },
            {
                name: "End Date",
                accessor: "end_date",
                value: false,
                filter: false,
            },
        ],
        [servicesList]
    );

    const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

    const getServicesList = (rows, page, search) => {
        dispatch(
            getServicesListAction(
                rows,
                page,
                search,
                (res) => {
                    setServicesList(res?.options);
                    setCount(res?.count);
                    setIsSearch(false);
                    navigate(`/services?page=${page}&rows=${rows}`);
                }
            )
        );
    };

    const handleSearch = (rows, page, search) => {
        setIsSearch(true);
        getServicesList(rows, 1, search);
    };

    const debouncedOnChange = useRef(debounce(handleSearch, 1000));

    const onPageChange = (e) => {
        getServicesList(
            e.rows,
            e.page + 1,
            search,
        );
        setFirst(e.first);
    };

    useEffect(() => {
        getServicesList(
            rows,
            page,
            "",
        );
    }, []);

    useEffect(() => {
        let filterdCols = columnOptions.filter((col) => col.value === true);
        setColumns(filterdCols);
    }, []);

    const handleServiceDelete = () => {
        dispatch(
            deleteServiceAction(
                idToDelete,
                setLoading,
                setDeleteModal,
                dispatch,
                (res) => {
                    getServicesList(rows, page, search);
                }
            )
        );
    };

    return (
        <div>
            <CustomCard title="Service List" header={header()}>
                <CustomTableContainer
                    list={servicesList}
                    columns={columns}
                    actions={actions}
                    setColumns={setColumns}
                    first={first}
                    rows={rows}
                    count={count}
                    ActionTemplate={ActionTemplate}
                    activeRow={service?.id}
                    slice={setServiceSlice}
                    handleSort={""}
                    onPageChange={onPageChange}
                    columnOptions={columnOptions}
                    setColumnOptions={setColumnOptions}
                    openAction={openAction}
                    firstCol="Services Name"
                    notShowSelection
                />
            </CustomCard>
            <CustomDeleteModal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                item={"Service"}
                onSubmit={handleServiceDelete}
                loading={loading}
            />
        </div>
    );
};

export default ServicesSetupList;
