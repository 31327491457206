import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { debounce } from "lodash";
import { CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomOverlay from "shared/CustomOverlay";
import CustomCard from "shared/CustomCard";
import {
  blockCustomerAction,
  deleteCustomerAction,
  getCustomersListAction,
  unblockCustomerAction,
} from "store/actions/CustomerActions";
import { setCustomerSlice } from "store/slices/CustomerSlices";
import CustomDeleteModal from "shared/Modals/CustomDeleteModal";

const CustomerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const globalFilters = ["name", "email"];
  const [filters2, setFilters2] = useState({
    name: { value: "" },
    email: { value: "" },
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    // phone_number: "",
  });
  const [first, setFirst] = useState(0);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const { customer } = useSelector((state) => state?.customer);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                rows,
                page,
                value,
                filters?.name,
                filters?.email
                // filters?.phone_number
              );
            }}
            isSearch={isSearch}
            placeholder="Search Customer"
          />
        </div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          extraClassNames="mr-3"
          label="Add Customer"
          onClick={() => navigate("/customers/add")}
        // disabled
        />
        {/* <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-upload text-dark"></i> Export
            </li>
          </ul>
        </CustomOverlay> */}
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/customers/view/${d.id}`);
              dispatch(setCustomerSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              handleCustomerBlockUnblock(d?.is_blocked, d?.company);
            }}
          >
            <i className="pi pi-ban text-dark"></i>{" "}
            {d?.is_blocked ? `Unblock` : `Block`}
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              setIdToDelete(d?.id);
              setDeleteModal(true);
            }}
          >
            <i className="pi pi-trash text-dark"></i>{" "}
            Delete
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p className="flex gap-2 align-items-center">Action</p>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        accessor: "name",
        value: true,
        frozen: true,
        filter: true,
        filterElement: (options) => NameFilter(options),
      },
      {
        name: "Email",
        accessor: "email",
        value: true,
        filter: true,
        filterElement: (options) => EmailFilter(options),
      },
      // {
      //   name: "Phone No",
      //   accessor: "phone_number",
      //   value: true,
      //   filter: true,
      //   filterElement: (option) => ContactFilter(option),
      // },
    ],
    [customerList, filters]
  );

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="name"
          // onChange={handleFilterChange}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          placeholder="Enter Name"
          ignoreLabel
        />
      </div>
    );
  };

  const EmailFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="email"
          // onChange={handleFilterChange}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  // const ContactFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         data={filters}
  //         name="phone_number"
  //         onChange={handleFilterChange}
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getCustomersList = (rows, page, search, name, email) => {
    dispatch(
      getCustomersListAction(
        rows,
        page,
        search,
        name,
        email,
        // phoneNumber,
        (res) => {
          setCustomerList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(`/customers/list?page=${page}&rows=${rows}`);
        }
      )
    );
  };

  const handleSearch = (rows, page, search, name, email) => {
    setIsSearch(true);
    getCustomersList(rows, 1, search, name, email);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const onPageChange = (e) => {
    // navigate(`/customers/list?page=${e.page + 1}&rows=${e.rows}`);
    getCustomersList(
      e.rows,
      e.page + 1,
      search,
      filters?.name,
      filters?.email
      // filters?.phone_number
    );
    setFirst(e.first);
  };

  const applyFilters = (options) => {
    getCustomersList(
      rows,
      1,
      "",
      filters?.name,
      filters?.email
      // filters?.phone_number
    );
    options.filterApplyCallback();
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getCustomersList(
      rows,
      1,
      "",
      field === "name" ? "" : filters?.name,
      field === "email" ? "" : filters?.email
      // field === "phone_number" ? "" : filters?.phone_number
    );
    options.filterClearCallback();
  };

  useEffect(() => {
    getCustomersList(
      rows,
      page,
      "",
      filters?.name,
      filters?.email
      // filters?.phone_number
    );
  }, []);

  const handleCustomerBlockUnblock = (status, company) => {
    if (!status) {
      dispatch(
        blockCustomerAction(company, dispatch, (res) => {
          if (res.success) {
            getCustomersList(
              rows,
              page,
              search,
              filters?.name,
              filters?.email
              // filters?.phone_number
            );
          }
        })
      );
    } else {
      dispatch(
        unblockCustomerAction(company, dispatch, (res) => {
          if (res.success) {
            getCustomersList(
              rows,
              page,
              search,
              filters?.name,
              filters?.email
              // filters?.phone_number
            );
          }
        })
      );
    }
  };

  const handleCustomerDelete = () => {
    dispatch(
      deleteCustomerAction(
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getCustomersList(
            rows,
            page,
            "",
            filters?.name,
            filters?.email
            // filters?.phone_number
          );
          setIdToDelete("")
        }
      )
    );
  };

  return (
    <div>
      <CustomCard title="Customers List" header={header()}>
        <CustomTableContainer
          list={customerList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={customer?.id}
          slice={setCustomerSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          notShowSelection
        />
      </CustomCard>
      <CustomDeleteModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        item={"Customer"}
        onSubmit={handleCustomerDelete}
        loading={loading}
      />
    </div>
  );
};

export default CustomerList;
