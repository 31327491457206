import React from "react";
import { Dialog } from "primereact/dialog";
import CustomButton, { CustomButtonOutlined } from "../CustomButton";
const CustomDeleteModal = ({
    isOpen,
    setIsOpen,
    heading,
    cancelLabel,
    submitLabel,
    loading,
    onSubmit,
    onCancel,
    item
}) => {
    const footerContent = (
        <div className="modal-footer border-0 gap-4 flex justify-content-center pb-4">
            <CustomButtonOutlined
                disabled={loading}
                type="button"
                label={cancelLabel || "Cancel"}
                className="btn m-0 fs-6 p-2 px-5 primary-button-outlined bg-transparent "
                onClick={() => {
                    setIsOpen(!isOpen);
                    onCancel && onCancel();
                }}
            />
            {onSubmit && (
                <CustomButton
                    type="button"
                    loading={loading}
                    label={submitLabel || "Confirm"}
                    onClick={onSubmit}
                    className="btn primary-button fs-6 p-2 text-white px-5"
                />
            )}
        </div>
    );
    return (
        <Dialog
            visible={isOpen}
            className="invitation-modal"
            header={heading || "Delete"}
            footer={footerContent}
            style={{ width: "30vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => {
                !loading && setIsOpen(false);
                onCancel && onCancel();
            }}
        >
            {<span>
                {`Are you sure you want to delete the selected ${item} from the list? This action cannot be undone.`}
            </span>}
        </Dialog>
    );
};

export default CustomDeleteModal;
