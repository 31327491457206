import { ReactComponent as Home } from "../../assets/images/svg/Home.svg";
import { ReactComponent as Overview } from "../../assets/images/svg/Overview.svg";
import { ReactComponent as ServicesSetup } from "assets/images/icons/servicesetupicon.svg";

import { ReactComponent as Tenant } from "../../assets/images/svg/Tenant.svg";
import { ReactComponent as Administrator } from "../../assets/images/svg/Administrator.svg";
import { ReactComponent as Reports } from "../../assets/images/svg/Reports.svg";
import { ReactComponent as Contract } from "../../assets/images/svg/Contract.svg";
import { ReactComponent as Settings } from "../../assets/images/svg/Settings.svg";
export const sidebarItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: Overview,
  },
  {
    title: "Customers",
    link: "/customers",
    icon: Home,
    items: [
      {
        title: "Customers List",
        link: "/list",
        codename: ""
      },
      {
        title: "Add Customer",
        link: "/add",
        codename: ""
      },
    ]
  },
  {
    title: "Service Setup",
    link: "/services",
    icon: ServicesSetup,
  }
  // {
  //   title: "Real Estate",
  //   link: "/real-estate",
  //   icon: Home,
  //   items: [
  //     {
  //       title: "Properties",
  //       link: "/property",
  //       items: [
  //         {
  //           title: "Property List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Property",
  //           link: "/add/building-structure",
  //           codename: ""
  //         },
  //         {
  //           title: "Contract List",
  //           link: "/contract/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Contract",
  //           link: "/contract/add",
  //           codename: ""
  //         },
  //         {
  //           title: "Building List",
  //           link: "/building/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Building",
  //           link: "/building/add",
  //           codename: ""
  //         },
  //       ],
  //     },
  //     {
  //       title: "Landlord",
  //       link: "/landlord",
  //       items: [
  //         {
  //           title: "Landlord List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Landlord",
  //           link: "/add",
  //           codename: ""
  //         },
  //       ],
  //     },
  //     {
  //       title: "Maintenance",
  //       link: "/maintenance",
  //       items: [
  //         {
  //           title: "Maintenance Req.",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Damage List",
  //           link: "/damage/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Damage",
  //           link: "/damage-report/add",
  //           codename: ""
  //         },
  //         {
  //           title: "Handyman List",
  //           link: "/handyman/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Handyman Add",
  //           link: "/handyman/add",
  //           codename: ""
  //         },
  //       ],
  //     },
  //     {
  //       title: "Annual Statement",
  //       link: "/annualstatement",
  //       items: [
  //         {
  //           title: "List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add",
  //           link: "/add",
  //           codename: ""
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: "Tenants",
  //   link: "/tenants",
  //   icon: Tenant,
  //   items: [
  //     { title: "Tenants List", link: "/list", codename: "" },
  //     { title: "Add Tenants", link: "/add", codename: "add_tenant" },
  //   ],
  // },
  // {
  //   title: "Administrator",
  //   link: "/administrator",
  //   icon: Administrator,
  //   items: [
  //     {
  //       title: "Office Unit",
  //       link: "/office-unit",
  //       items: [
  //         {
  //           title: "Office Unit List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Office Unit",
  //           link: "/add",
  //           codename: "add_officeunit"
  //         },
  //       ],
  //     },
  //     {
  //       title: "Office",
  //       link: "/office",
  //       items: [
  //         {
  //           title: "Office List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Office",
  //           link: "/add",
  //           codename: "add_office"
  //         },
  //       ],
  //     },
  //     {
  //       title: "Employees",
  //       link: "/employees",
  //       items: [
  //         {
  //           title: "Employees List",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Employees",
  //           link: "/add",
  //           codename: "add_employee"
  //         },
  //       ],
  //     },
  //     {
  //       title: "Roles (Profiles)",
  //       link: "/roles",
  //       items: [
  //         {
  //           title: "Roles",
  //           link: "/list",
  //           codename: ""
  //         },
  //         {
  //           title: "Add Roles",
  //           link: "/add",
  //           codename: "add_companyrole"
  //         },
  //       ],
  //     },
  //   ]
  // },
  // {
  //   title: "Reports",
  //   link: "/reports",
  //   codename: "",
  //   icon: Reports,
  // },
  // {
  //   title: "Contract Template",
  //   link: "/contract",
  //   codename: "",
  //   icon: Contract,
  // },
  // {
  //   title: "Settings",
  //   link: "/settings",
  //   codename: "",
  //   icon: Settings,
  //   items: [
  //     {
  //       title: "Handyman Service",
  //       link: "/handyman-services",
  //       codename: ""
  //     },
  //   ]
  // },
];
