import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { handleFormatDate } from "utils/commonFunctions";
import { setServiceSlice } from "store/slices/ServiceSlices";

const addServiceAction = (data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        service_name: data?.servicesName,
        service_type: data?.servicesType,
        property: data?.property,
        legal_reference: data?.legalReference,
        nick_name: data?.nickName,
        start_date: handleFormatDate(data?.startDate),
        end_date: handleFormatDate(data?.endDate)
    };

    const res = await api(
        "post",
        endPoints?.SERVICE,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const getServicesListAction = (rows, page, search, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.SERVICE + `?limit=${rows}&page=${page}&search=${search || ""}`,
    );

    if (res?.success) {
        const options = res?.data?.results?.map((item) => {
            return {
                ...item,
                name: item?.first_name + " " + item?.last_name
            }
        })

        onRes({ options: options, count: res?.data?.count || res?.data?.results?.length })
    }
};

const getServiceDetailAction = (id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.SERVICE + `/${id}`,
    );

    if (res?.success) {
        onRes(res?.data?.results)
        dispatch(setServiceSlice(res?.data?.results))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const updateServiceAction = (id, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        service_name: data?.servicesName,
        service_type: data?.servicesType,
        property: data?.property,
        legal_reference: data?.legalReference,
        nick_name: data?.nickName,
        start_date: handleFormatDate(data?.startDate),
        end_date: handleFormatDate(data?.endDate)
    };

    const res = await api(
        "put",
        endPoints?.SERVICE + id + "/",
        payload
    );
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(setServiceSlice(info))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const deleteServiceAction = (id, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.SERVICE + `${id}/`,
    );
    onRes(res)
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)

};

export {
    addServiceAction,
    getServicesListAction,
    getServiceDetailAction,
    updateServiceAction,
    deleteServiceAction
}