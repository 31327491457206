import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import commonSlice from "./slices/commonSlice";
import buildingSlice from "./slices/buildingSlices";
import customerSlice from "./slices/CustomerSlices";
import serviceSlice from "./slices/ServiceSlices";

export const store = configureStore({
  reducer: {
    user: userSlice,
    common: commonSlice,
    building: buildingSlice,
    customer: customerSlice,
    service: serviceSlice,
  },
});
