import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addServiceAction, getServiceDetailAction, updateServiceAction } from "store/actions/ServicesActions";
import { reformatDateString, showFormErrors } from "utils/commonFunctions";
import formValidation from "utils/validations";

const ServiceCotainer = () => {
    const params = useParams();
    const [data, setData] = useState({
        servicesName: "",
        servicesType: "",
        property: "",
        legalReference: "",
        nickName: "",
        startDate: "",
        endDate: "",
    })
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { service } = useSelector((state) => state.service);

    useEffect(() => {
        if (service?.id && params?.id && service?.id == params?.id) {
            setData({
                servicesName: service?.service_name,
                servicesType: service?.service_type,
                property: service?.property,
                legalReference: service?.legal_reference,
                nickName: service?.nick_name,
                startDate: reformatDateString(service?.start_date),
                endDate: reformatDateString(service?.end_date),
            })
        } else if (params?.id) {
            getServiceDetail(params?.id);
        }
    }, [params?.id]);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (params?.id) {
                dispatch(
                    updateServiceAction(
                        params?.id,
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate(`/services/view/${params?.id}`)
                        }
                    )
                )
            } else {
                dispatch(
                    addServiceAction(
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate('/services')
                        }
                    )
                )
            }
        }
    }

    const getServiceDetail = (id) => {
        dispatch(
            getServiceDetailAction(id, dispatch, (res) => {
                setData({
                    servicesName: res?.service_name,
                    servicesType: res?.service_type,
                    property: res?.property,
                    legalReference: res?.legal_reference,
                    nickName: res?.nick_name,
                    startDate: reformatDateString(res?.start_date),
                    endDate: reformatDateString(res?.end_date),
                })
            })
        );
    };

    return {
        data,
        handleChange,
        loading,
        onSubmit,
        navigate,
    }
}

export default ServiceCotainer