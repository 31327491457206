import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        setServiceSlice: (state, action) => {
            state.service = action.payload
        },
    },

});

export const { setServiceSlice } = serviceSlice.actions;
export default serviceSlice.reducer;